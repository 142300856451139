import React from 'react'; 

const PopUpMessage = ({ isSuccess, message }) => {
  return (
    <div className={`popup-message ${isSuccess ? 'success' : 'failure'}`}>
      <strong>{isSuccess ? 'Başarılı !' : 'Hata!'}</strong>
      <p>{message}</p>
    </div>
  );
};

export default PopUpMessage;