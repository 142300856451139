import { Link } from 'react-router-dom';
import { useState } from 'react';
import Logo from "./Logo";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Daha yumuşak bir kaydırma efekti ekler
        });
    };

    return (
        <nav className="fixed top-0 w-full bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded shadow-md z-50">
            <div className="container flex flex-wrap justify-between items-center mx-auto">
                <div className="flex items-center">
                    <Link to="/" className="flex items-center" onClick={() => scrollToTop()}>
                        <Logo />
                        <h1 className="text-xl text-red-700 font-bold mx-2">Soner Üveren Sigorta</h1>
                    </Link>
                </div>
                <button onClick={() => setIsOpen(!isOpen)} className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    {/* Icon for the button */}
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                </button>
                <div className={`${isOpen ? 'block' : 'hidden'} w-full md:block md:w-auto`} id="navbar-default">
                    <ul className="flex flex-col p-4 mt-4 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:items-center">
                        <li>
                            <Link to="/" className="block py-3 pr-4 pl-3 text-red-700 hover:bg-red-100 hover:text-red-700 md:hover:bg-transparent md:hover:text-red-700" onClick={() => {setIsOpen(false); scrollToTop();}}>Ana Sayfa</Link>
                        </li>
                        <li>
                            <Link to="/about-us" className="block py-3 pr-4 pl-3 text-red-700 hover:bg-red-100 hover:text-red-700 md:hover:bg-transparent md:hover:text-red-700" onClick={() => setIsOpen(false)}>Hakkımızda</Link>
                        </li>
                        <li>
                            <Link to="/get-quote" className="block py-3 rounded pr-4 pl-3 bg-red-700 text-white font-bold hover:bg-red-800 transition duration-300 ease-in-out transform hover:-translate-y-1" onClick={() => setIsOpen(false)}>Teklif Al!</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
