import React, { useState } from 'react';
import PopUpMessage from './PopUpMessage'; // Adjust the path as necessary
import { Fade } from 'react-awesome-reveal';

const GetQuote = () => {
  const [formData, setFormData] = useState({ name: '', surname: '', phone: '', email: '', description: '' });
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [popupInfo, setPopupInfo] = useState({ isSuccess: false, message: '' });
  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if (!formData.name.trim()) {
      errors.name = 'Lütfen adınızı giriniz!';
      formIsValid = false;
    }

    if (!formData.surname.trim()) {
      errors.surname = 'Lütfen soyadınızı giriniz!';
      formIsValid = false;
    }

    if (!formData.phone.trim()) {
      errors.phone = 'Lütfen telefon numaranızı giriniz!';
      formIsValid = false;
    } else if (!/^\d{11}$/.test(formData.phone)) {
      errors.phone = 'Lütfen telefon numranızı başında 0 olacak şekilde giriniz!';
      formIsValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = 'Lütfen mailinizi giriniz!';
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Lütfen geçerli bir email adresi giriniz!';
      formIsValid = false;
    }

    if (!formData.description.trim()) {
      errors.description = 'Lütfen bu bölgeyi doldurunuz';
      formIsValid = false;
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch('https://insurance-company-website.onrender.com/send-email', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...formData, to: 'soner@soneruverensigorta.com' }),
        });

        if (!response.ok) throw new Error('Network response was not ok');
        setPopupInfo({ isSuccess: true, message: 'Teklif isteğiniz alınmıştır. En yakın zamanda size dönüş yapacağız!' });
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 4000);
        setFormData({ name: '', surname: '', phone: '', email: '', description: '' }); // Reset form fields
      } catch (error) {
        setPopupInfo({ isSuccess: false, message: 'Lütfen daha sonra tekrar deneyin!' });
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 4000);
        console.error('Error submitting form:', error);
      }
    }
  };
  
  return (
    <div className="max-w-md mx-auto my-10 pt-[60px]">
      <Fade direction='left'>
      <div className="p-2 font-bold text-red-700 mb-4">
        Lütfen teklif almak için bilgileri doldurunuz!
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <label htmlFor="name">Ad</label>
        <input
          type="text"
          name="name"
          id="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Ad"
          className="p-2 border border-gray-300 rounded"
        />
        {errors.name && <p className="text-red-600">{errors.name}</p>}
        <hr />

        <label htmlFor="surname">Soyad</label>
        <input
          type="text"
          name="surname"
          id="surname"
          value={formData.surname}
          onChange={handleChange}
          placeholder="Soyad"
          className="p-2 border border-gray-300 rounded"
        />
        {errors.surname && <p className="text-red-600">{errors.surname}</p>}
        <hr />

        <label htmlFor="phone">Telefon Numarası</label>
        <input
          type="tel"
          name="phone"
          id="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Telefon Numarası"
          className="p-2 border border-gray-300 rounded"
        />
        {errors.phone && <p className="text-red-600">{errors.phone}</p>}
        <hr />

        <label htmlFor="email">Email Adresi</label>
        <input
          type="email"
          name="email"
          id="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email Adresi"
          className="p-2 border border-gray-300 rounded"
        />
        {errors.email && <p className="text-red-600">{errors.email}</p>}
        <hr />

        <label htmlFor="description">Size nasıl yardımcı olabiliriz?</label>
        <textarea
          name="description"
          id="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Size nasıl yardımcı olabiliriz?"
          className="p-2 border border-gray-300 rounded"
        />
        {errors.description && <p className="text-red-600">{errors.description}</p>}

        <button type="submit" className="p-2 font-bold bg-red-700 text-white rounded hover:bg-red-800 transition duration-300 ease-in-out transform hover:-translate-y-1">
          Teklif Al!
        </button>
      </form>
      {showPopup && (
        <div style={{ position: 'fixed', bottom: '20px', left: '20px', zIndex: 1000 }}>
          <Fade direction="up" triggerOnce>
            <PopUpMessage isSuccess={popupInfo.isSuccess} message={popupInfo.message} />
          </Fade>
        </div>
      )}
      </Fade>
    </div>
  );
};

export default GetQuote;
