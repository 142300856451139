import React from 'react';
import locationImage from '../Assets/images/location.png'; // Adjust the path as needed based on your file structure


const Footer = () => {
  return (
    <footer className="bg-white text-gray-800 p-4 mt-8 shadow-lg">
  <div className="container mx-auto flex flex-wrap justify-between items-center">
    <div className="flex-1">
      <h2 className="text-lg font-semibold text-red-700">Soner Üveren Sigorta Aracılık Hizmetleri Ltd. Şti.</h2>
      <p>Adres: Onur Mah. Karanfil Sokak No: 38/A Balçova - İZMİR</p>
      <p>Tel: (0232) 259 25 12 - 277 55 51</p>
      <p>Phone: 0532 621 17 75</p>
      <p>Email: <a href="mailto:soner@soneruverensigorta.com" className="text-red-700 hover:text-red-500">soner@soneruverensigorta.com</a></p>
    </div>
    <div className="flex-1 text-right">
      <a href="https://www.google.com/maps/place/Soner+%C3%9Cveren+Sigorta+Arac%C4%B1l%C4%B1k+Hizmetleri+Ltd.%C5%9Eti./@38.3896635,27.0556964,15z/data=!4m6!3m5!1s0x14bbdc4082670cd7:0xd3b8122a2ccaceff!8m2!3d38.3896635!4d27.0556964!16s%2Fg%2F11fb04nglf?entry=ttu" target="_blank" rel="noopener noreferrer">
        <img src={locationImage} alt="Location" className="inline-block w-60 h-38 object-contain border border-gray-300 rounded-lg p-1" />
      </a>
    </div>
  </div>
</footer>

  );
};

export default Footer;
