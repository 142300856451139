import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Fade } from 'react-awesome-reveal';

// Resim ve ikonların import edilmesi
import contentImage2 from '../Assets/images/content-image-2.png';
import contentImage3 from '../Assets/images/content-image-3.png';
import contentImage4 from '../Assets/images/content-image-4.png';
import contentImage5 from '../Assets/images/content-image-5.png';

import houseInsuranceIcon from '../Assets/icons/house-insurance-icon.png';
import carInsuranceIcon from '../Assets/icons/car-insurance-icon.png';
import workInsuranceIcon from '../Assets/icons/business-insurance-icon.png';
import healthInsuranceIcon from '../Assets/icons/health-insurance-icon.png';

import allianzLogo from '../Assets/logos/allianz-logo.png';
import aksigortaLogo from '../Assets/logos/aksigorta-logo.png';
import sompoLogo from '../Assets/logos/sompo-logo.png';
import acnturkLogo from '../Assets/logos/acnturk-logo.png';
import hdiLogo from '../Assets/logos/hdi-logo.png';
import hepiyiLogo from '../Assets/logos/hepiyi-logo.png';
import zurichLogo from '../Assets/logos/zurich-logo.png';
import generaliLogo from '../Assets/logos/generali-logo.png';
import quicksigortaLogo from '../Assets/logos/quicksigorta-logo.png';

import adImage1 from '../Assets/images/img-1.jpg';
import adImage2 from '../Assets/images/img-3.jpg';
import adImage3 from '../Assets/images/img-2.jpg';

const Home = () => {
  // Slayt gösterisi için ayarlar
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 300000,
  };

  return (
    <div className="pt-[30px]">
      <div>
        {/* Content Section - Slayt Gösterisi */}
        
        <Fade direction='down'>
           {/* Slogan */}
           <div className="text-center my-8">
            <h2 className="text-4xl font-semibold">Güven ve Huzurun Adresi</h2>
            <p className="mt-2 text-lg text-gray-600">Sizin için en iyi sigorta çözümleri burada.</p>
          </div>

        </Fade>
           
        
          <Fade>
          <Slider {...sliderSettings}>
            <div className='slick-slide'>
              <img src={contentImage2} alt="Content 2" className="slider-image" />
            </div>
            <div className='slick-slide'>
              <img src={contentImage3} alt="Content 3" className="slider-image" />
            </div>
            <div className='slick-slide'>
              <img src={contentImage5} alt="Content 4" className="slider-image" />
            </div>
            <div className='slick-slide'>
              <img src={contentImage4} alt="Content 3" className="slider-image" />
            </div>
       
          </Slider>
        </Fade>
        <br />
      </div>

      <br /><br />

      <Fade direction='left'>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-3 justify-center items-center px-5"> {/* gap ve mt değerlerini artırdım */}
          {[
            { to: '/house-insurance', img: houseInsuranceIcon, text: 'Konut Sigortası' },
            { to: '/car-insurance', img: carInsuranceIcon, text: 'Araç Sigortası' },
            { to: '/work-insurance', img: workInsuranceIcon, text: 'İşyeri Sigortası' },
            { to: '/travel-health-insurance', img: healthInsuranceIcon, text: 'Sağlık Sigortası' },
          ].map((insurance, index) => (
            <Link key={index} to={insurance.to} className="flex flex-col items-center bg-red-700 hover:bg-red-800 text-white font-bold py-3 px-5 rounded shadow-lg transform hover:-translate-y-1 transition duration-300 ease-in-out"> {/* py ve px değerlerini artırdım */}
              <img src={insurance.img} alt={insurance.text} className="w-16 h-16 mb-2" /> {/* Resim boyutunu ve altındaki boşluğu artırdım */}
              {insurance.text}
            </Link>
          ))}
        </div>
      </Fade>
      <br />

      {/* Ayraç çizgisi */}
      <div className="my-8">
        <div className="border-t border-gray-300 shadow"></div> {/* Yatay ayırıcı çizgi */}
      </div>
      <br />

      {/* Beraber Çalıştığımız Ajanslar Başlığı */}
      <Fade direction='up'>
      <div className="text-center">
        <h2 className="text-3xl font-semibold">Acentesi olduğumuz şirketler</h2>
        <p className="mt-2 text-lg text-gray-600">Sektörün öncü şirketleriyle güç birliği yapıyoruz!</p>
      </div>
      </Fade>
      

      {/* Sigorta Acenteleri Logoları */}
      <Fade>
  <div className="flex flex-wrap justify-center items-center px-5">
      <img src={allianzLogo} alt="Allianz Logo" className="m-5 w-24" />
      <img src={aksigortaLogo} alt="Aksigorta Logo" className="m-5 w-24" />
      <img src={sompoLogo} alt="Sompo Sigorta Logo" className="m-5 w-24" />
      <img src={acnturkLogo} alt="ACN Türk Logo" className="m-5 w-24" />
      <img src={hdiLogo} alt="HDI Sigorta Logo" className="m-5 w-24" />
      <img src={hepiyiLogo} alt="Hepiyi Logo" className="m-5 w-24" />
      <img src={zurichLogo} alt="Zurich Sigorta Logo" className="m-5 w-24" />
      <img src={quicksigortaLogo} alt="Quick Sigorta Logo" className="m-5 w-24" />
      <img src={generaliLogo} alt="Generali Sigorta Logo" className="m-5 w-24" />
  </div>
</Fade>

      {/* Ayraç çizgisi */}
      <div className="my-8">
        <div className="border-t border-gray-300 shadow"></div> {/* Yatay ayırıcı çizgi */}
      </div>


      {/* Reklam Kartları */}
      <Fade direction='right'>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 px-5">
          {[
            { img: adImage1, title: 'Özel Sigorta Paketleri', description: 'Size özel sigorta paketlerimizle tanışın.' },
            { img: adImage2, title: 'Güvenli Gelecek', description: 'Güvenli bir gelecek için yanınızdayız.' },
            { img: adImage3, title: 'Aile Sigortası', description: 'Ailenizin her anını güvence altına alın.' },
          ].map((ad, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden transform hover:-translate-y-1 transition duration-300 ease-in-out">
              <img src={ad.img} alt={`Reklam ${index + 1}`} className="w-full h-32 sm:h-48 object-cover" />
              <div className="p-5">
                <h3 className="font-bold text-lg mb-2">{ad.title}</h3>
                <p className="text-gray-700">{ad.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Fade>



      <br /><br />
      <Fade direction='left'>
        <Link to="/get-quote" className="block">
          <div className="bg-red-700 text-white text-center p-5 rounded-lg shadow-lg hover:bg-red-800 transition duration-300 ease-in-out transform hover:-translate-y-1">
            <h2 className="text-5xl font-bold text-white">Teklif Al!</h2>
            <p className="text-2xl mt-2">Güvenilir sigorta çözümleri için doğru yerdesiniz.</p>
          </div>
        </Link>
      </Fade>
    </div>

  );
}

export default Home;
