import React from 'react';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import carInsuranceImage from '../../Assets/images/car-insurance-image.png'; // Araba Sigortası için bir görsel ekleyin

const CarInsurance = () => {
  return (
    <div className="container mx-auto my-8 px-4">
      <Fade direction='left'>
      <div className="bg-cover bg-center h-96 w-50" style={{ backgroundImage: `url(${carInsuranceImage})` }}></div> {/* Araba Sigortası Görseli */}
      <div className="mt-4">
        <h1 className="text-3xl font-bold text-red-700">Araba Sigortası</h1>
        <p className="mt-2 text-lg text-gray-700">
          Araba Sigortası, aracınızın karşılaşabileceği birçok riski kapsar. Kaza, çalınma ve doğal afetler gibi durumlar için güvence sağlar.
          Detaylı bilgi ve teklif almak için "Teklif Al!"" butonuna tıklayın. 
        </p>
        <p className="mt-2 text-lg text-gray-700">
          Dilerseniz aşağıdaki butondan bir kaza tutanağı yazdırabilirsiniz.
        </p>
        <a href={`${process.env.PUBLIC_URL}/kaza-tutanagi.pdf`} download="kaza-tutanagi.pdf" className="mt-4 inline-block bg-blue-400 text-white font-bold py-2 px-4 rounded hover:bg-red-800">
            Kaza Tutanağı Yazdır!
        </a>
        <br /><br />
        <Link to="/get-quote" className="block">
        <div className="bg-red-700 text-white text-center p-5 rounded-lg shadow-lg hover:bg-red-800 transition duration-300 ease-in-out transform hover:-translate-y-1">
          <h2 className="text-5xl font-bold text-white">Teklif Al!</h2>
          <p className="text-2xl mt-2">Güvenilir sigorta çözümleri için doğru yerdesiniz.</p>
        </div>
      </Link>
      </div>
      </Fade>
    </div>
  );
}

export default CarInsurance;
