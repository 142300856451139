import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import GetQuote from './Components/GetQuote';
import AboutUs from './Components/AboutUs';
import LocationNotification from './Components/LocationNotification';
import Footer from './Components/Footer';
import CarInsurance from './Components/Insurances/CarInsurance';
import HouseInsurance from './Components/Insurances/HouseInsurance';
import WorkInsurance from './Components/Insurances/WorkInsurance';
import TravelHealthInsurance from './Components/Insurances/TravelHealthInsurance';



function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar />
        <div className="content">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/car-insurance" component={CarInsurance} /> {/* CarInsurance için Route */}
            <Route path="/get-quote" component={GetQuote} />
            <Route path="/house-insurance" component={HouseInsurance} />
            <Route path="/work-insurance" component={WorkInsurance} />
            <Route path="/travel-health-insurance" component={TravelHealthInsurance} />
          </Switch>
          <LocationNotification></LocationNotification>
        </div>
        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;