import React from 'react';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import travelHealthInsuranceImage from '../../Assets/images/travel-health-insurance-image.png'; // Make sure you have an appropriate image

const TravelHealthInsurance = () => {
  return (
    <div className="container mx-auto my-8 px-4 mt-20">
      <Fade direction='left'>
        <div className="bg-contain bg-center bg-no-repeat h-96 w-full" style={{ backgroundImage: `url(${travelHealthInsuranceImage})` }}></div> {/* Travel Health Insurance Image */}
        <div className="mt-4">
          <h1 className="text-3xl font-bold text-red-700">Seyahat Sağlık Sigortası</h1>
          <p className="mt-2 text-lg text-gray-700">
            Seyahat Sağlık Sigortası, yurt dışı seyahatlerinizde karşılaşabileceğiniz sağlık sorunlarına karşı sizi güvence altına alır. Acil sağlık hizmetlerinden, hastane masraflarına kadar geniş bir koruma sağlar.
            Detaylı bilgi ve teklif almak için aşağıdaki butona tıklayın.
          </p>
          <br />

          <h1 className="text-3xl font-bold text-blue-700">İkamet için Yabancı Sağlık Sigortası</h1>
          <p className="mt-2 text-lg text-gray-700">
          Yabancı Sağlık Sigortası, Türkiye'de minimum 1 yıl veya daha uzun süre ikamet edecek yabancı uyruklu bireylerin zorunlu yaptırması gereken
            bir sigorta türüdür. Bir Başka deyimle, Türk vatandaşlığına sahip olmayan yabancıların, ikamet izni ya da oturma izni almak için satın aldıkları sağlık sigortasıdır.
          </p>
            <br />
          <Link to="/get-quote" className="mt-4 block bg-red-700 text-white text-center p-5 rounded-lg shadow-lg hover:bg-red-800 transition duration-300 ease-in-out transform hover:-translate-y-1">
            <h2 className="text-5xl text-white font-bold">Teklif Al!</h2>
            <p className="text-2xl text-white mt-2">Güvenli ve huzurlu seyahatler için yanınızdayız.</p>
          </Link>


        </div>
      </Fade>
    </div>
  );
}

export default TravelHealthInsurance;