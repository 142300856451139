import { Fade } from 'react-awesome-reveal';
import domates from '../Assets/images/content-image-1.png';
const AboutUs = () => {
    return (

        <div className="container mx-auto my-12 px-4 pt-[10px]">
            <Fade direction='up'>
            <h2 className="text-4xl font-bold text-center mb-6 text-red-600">23 Yıldır Sektördeyiz!</h2>
            </Fade>
            <br />
            <div className="flex flex-wrap -mx-4">
                <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                    <img src={domates} alt="Hakkımızda" className="rounded shadow-md"/>
                </div>
                <div className="w-full lg:w-1/2 px-4">
                    <p className="text-lg text-gray-700">
                        Soner Üveren Sigorta Aracılık Hizmetleri Ltd Şti, 04.07.2001 tarihinde kurulmuştur. Kurulduğu günden bugüne uygun fiyat ve kaliteli hizmet ilkesinden ödün vermeden gelişimini hızla sürdürmüş ve siz sigortalılarımızın desteğiyle sürdürmeye devam etmektedir. Sigorta sektöründe faaliyet gösteren şirketimiz; Araç, Konut, İşyeri, Kaza, Sağlık, Nakliyat, Sorumluluk sigortaları ile siz değerli sigortalılarımıza hizmet vermektedir.
                    </p>
                    <p className="mt-4 text-lg text-gray-700">
                        Güvenilirlik ve müşteri memnuniyetini ilke edinmiş bir kurum olarak, dürüst sigortacılık anlayışı ile siz değerli müşterilerimizle çalışmaktan mutluluk duymaktayız.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
