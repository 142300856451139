import React from 'react';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import houseInsuranceImage from '../../Assets/images/house-insurance-image.png'; // Make sure to add a house insurance image

const HouseInsurance = () => {
  return (
    <div className="container mx-auto my-8 px-4 mt-20">
      <Fade direction='left'>
      <div className="bg-contain bg-center bg-no-repeat h-96 w-full" style={{ backgroundImage: `url(${houseInsuranceImage})` }}></div> {/* House Insurance Image */}
        <div className="mt-4">
          <h1 className="text-3xl font-bold text-red-700">Ev Sigortası</h1>
          <p className="mt-2 text-lg text-gray-700">
            Ev Sigortası, evinizin karşılaşabileceği birçok riski kapsar. Yangın, hırsızlık, sel gibi durumlar için güvence sağlar.
            Detaylı bilgi ve teklif almak için aşağıdaki butona tıklayın.
          </p>
          <Link to="/get-quote" className="mt-4 block bg-red-700 text-white text-center p-5 rounded-lg shadow-lg hover:bg-red-800 transition duration-300 ease-in-out transform hover:-translate-y-1">
            <h2 className="text-5xl text-white font-bold">Teklif Al!</h2>
            <p className="text-2xl text-white mt-2">Güvenilir sigorta çözümleri için doğru yerdesiniz.</p>
          </Link>
        </div>
      </Fade>
    </div>
  );
}

export default HouseInsurance;
