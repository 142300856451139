import React from 'react';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import workInsuranceImage from '../../Assets/images/work-insurance-image.png'; // Ensure you have a work insurance image

const WorkInsurance = () => {
  return (
    <div className="container mx-auto my-8 px-4 mt-20">
      <Fade direction='left'>
        <div className="bg-contain bg-center bg-no-repeat h-96 w-full" style={{ backgroundImage: `url(${workInsuranceImage})` }}></div> {/* Work Insurance Image */}
        <div className="mt-4">
          <h1 className="text-3xl font-bold text-red-700">İş Yeri Sigortası</h1>
          <p className="mt-2 text-lg text-gray-700">
            İş Yeri Sigortası, iş yerinizin karşılaşabileceği risklere karşı koruma sağlar. Yangın, hırsızlık, su baskını gibi durumlar için kapsamlı güvence sunar.
            Detaylı bilgi ve teklif almak için aşağıdaki butona tıklayın.
          </p>
          <Link to="/get-quote" className="mt-4 block bg-red-700 text-white text-center p-5 rounded-lg shadow-lg hover:bg-red-800 transition duration-300 ease-in-out transform hover:-translate-y-1">
            <h2 className="text-5xl text-white font-bold">Teklif Al!</h2>
            <p className="text-2xl text-white mt-2">İş yeriniz için güvenilir sigorta çözümleri.</p>
          </Link>
        </div>
      </Fade>
    </div>
  );
}

export default WorkInsurance;
