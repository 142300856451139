import React, { useState, useEffect } from 'react';
import locationIcon from '../Assets/icons/location-icon.png';

const LocationNotification = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    const scrolledFromTop = window.scrollY;
    const totalPageHeight = document.body.offsetHeight;
    const distanceFromBottom = totalPageHeight - (scrolledFromTop + window.innerHeight);

    setIsVisible(distanceFromBottom > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    isVisible && (
      <img
        src={locationIcon}
        alt="Location Icon"
        className="fixed bottom-4 right-4 w-10 h-10 cursor-pointer z-50 animate-bounce"        
        onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}
      />
    )
  );
};

export default LocationNotification;
